import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const SingleSlide = styled.div`
  border: 1px solid #e5ebf8;
  border-radius: 5px;
  background-color: #fff;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  padding: 0 15px;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
`;

const ClientsSlider = ({ logos }) => {
  const clientsSlider = {
    slidesToShow: 6,
    slidesToScroll: 6,
    dots: true,
    arrows: false,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <div className="clients-slider">
      <Slider {...clientsSlider}>
        {logos.map((logo, index) => {
          return (
            <div key={index}>
              <SingleSlide>
                <Image
                  src={logo.ourClientsClient?.sourceUrl}
                  alt=""
                  className="img-fluid"
                />
              </SingleSlide>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ClientsSlider;
