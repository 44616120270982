import "styles/pages/page-home.scss";

import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionHeading from "components/SectionHeading/";
import ClientsSlider from "components/ClientsSlider/ClientsSlider";
import TestimonialsSlider from "components/TestimonialsSlider/TestimonialsSlider";
import NewContactForm from "components/NewContactForm";
import Newsletter from "components/Newsletter";

const HomePage = ({ pageContext, data }) => {
  const homePage = pageContext.pageContent;
  const testimonials = data.testimonials.edges;

  const video_icons = [
    require("assets/videos/icons/usability.mp4").default,
    require("assets/videos/icons/knowledge.mp4").default,
    require("assets/videos/icons/partners.mp4").default,
  ];

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />

      <section className="home-hero">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-11">
              <div className="home-hero__text-wrapper">
                <h1 className="home-hero__title">{homePage.heroTitle}</h1>
                <div
                  className="home-hero__text"
                  dangerouslySetInnerHTML={{
                    __html: homePage.heroSubtitle,
                  }}
                />
                <Link to="/solutions/" className="btn btn--white">
                  See more
                </Link>
              </div>
            </div>
          </div>
        </div>
        <a href="#pse" className="home-hero__scroll">
          <img src={require("assets/icons/mouse-scroll.svg").default} alt="" />
        </a>
        <video autoPlay={true} muted={true} loop={true} playsInline={true}>
          <source
            src={require("assets/videos/home-hero.mp4").default}
            type="video/mp4"
          />
        </video>
      </section>

      <span className="anchor anchor--120" id="pse"></span>
      <section className="pse-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 pse-section__tile">
              <h2 className="pse-section__tile-title">
                {homePage.pseProblemTitle}
              </h2>
              <div className="pse-section__single-tile pse-section__single-tile--shadow">
                <div className="pse-section__tile-heading">
                  {homePage.pseProblemSubtitle}
                </div>
                <div
                  className="pse-section__text"
                  dangerouslySetInnerHTML={{
                    __html: homePage.pseProblemText,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 pse-section__tile">
              <h2 className="pse-section__tile-title">
                {homePage.pseSolutionTitle}
              </h2>
              <div className="pse-section__single-tile">
                <div className="pse-section__tile-heading">
                  {homePage.pseSolutionSubtitle}
                </div>
                <div
                  className="pse-section__text"
                  dangerouslySetInnerHTML={{
                    __html: homePage.pseSolutionText,
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 pse-section__tile">
              <h2 className="pse-section__tile-title">
                {homePage.pseEffectTitle}
              </h2>
              <div className="pse-section__single-tile">
                <div className="pse-section__tile-heading">
                  {homePage.pseEffectSubtitle}
                </div>
                <div
                  className="pse-section__text"
                  dangerouslySetInnerHTML={{
                    __html: homePage.pseEffectText,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-benefits">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-11">
              <h2 className="home-benefits__title">{homePage.divider1Title}</h2>
              <h3 className="home-benefits__text">
                {homePage.divider1Subtitle}
              </h3>
              <Link to="/benefits/" className="btn btn--outline">
                Learn more
              </Link>
            </div>
          </div>
        </div>
        <video autoPlay={true} muted={true} loop={true} playsInline={true}>
          <source
            src={require("assets/videos/home-benefits.mp4").default}
            type="video/mp4"
          />
        </video>
      </section>

      <section className="home-solutions">
        <div className="container">
          <SectionHeading title={homePage.endToEndTitle} variant="small" />
          <div className="row">
            {homePage.endToEndColumns.map((col, index) => {
              return (
                <div className="col-lg-3 col-sm-6" key={index}>
                  <div className="home-solutions__single-item">
                    <img
                      src={col.endToEndColumnImage?.sourceUrl}
                      alt=""
                      className="img-fluid home-solutions__img"
                    />
                    <h3 className="home-solutions__item-title">
                      {col.endToEndColumnTitle}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="home-what-we-do">
        <div className="container">
          <SectionHeading
            title={homePage.wwdTitle}
            subtitle={homePage.wwdSubtitle}
          />
          <div className="row">
            <div className="col-lg-4">
              <div className="single-item">
                <div className="single-item__title">{homePage.wwd1Title}</div>
                <div className="single-item__tile single-item__tile--shadow">
                  {homePage.wwd1List.map((listItem, index) => {
                    return (
                      <div className="single-item__tile-text" key={index}>
                        {listItem.wwd1ListItem}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="single-item">
                <div className="single-item__title single-item__title--pink">
                  {homePage.wwd2Title}
                </div>
                <div className="single-item__tile single-item__tile--pink">
                  {homePage.wwd2List.map((listItem, index) => {
                    return (
                      <div
                        className="single-item__tile-text single-item__tile-text--error"
                        key={index}
                      >
                        {listItem.wwd2ListItem}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="single-item">
                <div className="single-item__title">{homePage.wwd3Title}</div>
                <div className="single-item__tile">
                  {homePage.wwd3List.map((listItem, index) => {
                    return (
                      <div className="single-item__tile-text" key={index}>
                        {listItem.wwd3ListItem}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="center-element">
            <Link to="/solutions/" className="btn home-what-we-do__btn">
              Learn more
            </Link>
          </div>
        </div>
      </section>

      <Newsletter isHome />

      <section className="home-data-solutions">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <h2 className="home-data-solutions__title">
                {homePage.divider2Title}
              </h2>
              <div className="home-data-solutions__text">
                {homePage.divider2Text}
              </div>
              <div className="home-data-solutions__text home-data-solutions__text--pink">
                {homePage.divider2Subtext}
              </div>
            </div>
          </div>
        </div>
        <video autoPlay={true} muted={true} loop={true} playsInline={true}>
          <source
            src={require("assets/videos/pattern.mp4").default}
            type="video/mp4"
          />
        </video>
      </section>

      <section className="home-how-we-work">
        <div className="home-how-we-work__main-text-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <SectionHeading
                  title={homePage.howDoWeWorkTitle}
                  color="white"
                />
                <div className="home-how-we-work__desc">
                  {homePage.howDoWeWorkText}
                  <br />
                  <br />
                  <span>{homePage.howDoWeWorkSubtext}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="home-how-we-work__tiles">
            <div className="row">
              {homePage.howDoWeWorkCols.map((hdww, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-6 single-block__col"
                    key={index}
                  >
                    <div className="single-block">
                      <div className="single-block__title">
                        {hdww.howDoWeWorkColTitle}
                      </div>
                      <div className="single-block__divider" />
                      <div className="single-block__text">
                        {hdww.howDoWeWorkColText}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="home-why-us">
        <div className="container">
          <SectionHeading
            title={homePage.whyUsTitle}
            subtitle={homePage.whyUsSubtitle}
          />
          <div className="row">
            {homePage.whyUsTiles.map((tile, index) => (
              <div className="col-lg-4 " key={index}>
                <div className="home-why-us__single-tile">
                  {/* <img
                      src={tile.sectionWuTileIcon.sourceUrl}
                      alt=""
                      className="home-why-us__icon"
                    /> */}

                  <video
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    playsInline={true}
                  >
                    <source src={video_icons[index]} type="video/mp4" />
                  </video>
                  <div className="home-why-us__tile-title">
                    {tile.sectionWuTileTitle}
                  </div>
                  <div className="home-why-us__tile-text">
                    {tile.sectionWuTileText}
                  </div>
                  <div className="home-why-us__tile-bottom-text">
                    {tile.sectionWuTileSubtext}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="home-for-who">
        <div className="container">
          <SectionHeading
            title={homePage.bestSolutionTitle}
            variant="verySmall"
          />
          <div className="row">
            {homePage.bestSolutionTiles.map((tile, index) => {
              return (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div className="home-for-who__single-tile">
                    {tile.bestSolutionTileText}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="home-appointment">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <h2 className="home-appointment__title">
                {homePage.divider3Title}
              </h2>
              <Link to="/contact/" className="btn">
                Make an appointment
              </Link>
            </div>
          </div>
        </div>
        <video autoPlay={true} muted={true} loop={true} playsInline={true}>
          <source
            src={require("assets/videos/pattern.mp4").default}
            type="video/mp4"
          />
        </video>
      </section>

      <section className="home-clients">
        <div className="container">
          <SectionHeading
            title={homePage.ourClientsTitle}
            subtitle={homePage.ourClientsSubtitle}
          />
          <div className="home-clients__slider-container">
            <ClientsSlider logos={homePage.ourClientsClients} />
          </div>
        </div>
      </section>

      <section className="home-testimonials">
        <div className="container">
          <SectionHeading title={homePage.testimonialsTitle} margin="bottom" />
          <TestimonialsSlider testimonials={testimonials} />
        </div>
      </section>

      <NewContactForm />
    </Layout>
  );
};

export const query = graphql`
  query {
    caseStudy: allWpCaseStudy(limit: 4) {
      edges {
        node {
          title
          slug
          uri
          seo {
            metaDesc
            title
          }
          caseStudiesShortDesc {
            shortDescription
          }
          caseStudyCategories {
            nodes {
              name
              caseStudyCategoryColor {
                categoryColor
              }
            }
          }
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
    testimonials: allWpTestimonial {
      edges {
        node {
          testimonialContent {
            avatar {
              sourceUrl
            }
            liUrl
            logo {
              sourceUrl
            }
            name
            role
            text
          }
        }
      }
    }
  }
`;

export default HomePage;
