import "./styles.scss";

import React, { useState } from "react";
import axios from "axios";

const Newsletter = ({ isHome }) => {
  const [email, setEmail] = useState("");
  const [send, setSend] = useState(false);
  const [legal, setLegal] = useState(false);
  const [success, setSuccess] = useState(false);

  const resetForm = () => {
    setEmail("");
    setLegal(false);
    setSend(false);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setSend(true);
    setSuccess(true);

    const formData = new FormData();
    formData.set("email", email);
    formData.set("legal", legal);

    axios({
      method: "post",
      url: "https://wp.prodinity.com/wp-json/contact-form-7/v1/contact-forms/1755/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success");
        resetForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <section className={`newsletter${isHome ? " newsletter--home" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-4 offset-xl-1">
            <h3>Join our newsletter</h3>
            <p>
              Subscribe to our newsletter to receive latest news, delivered
              straight to your inbox.
            </p>
          </div>
          <div className="col-xl-5 offset-xl-1">
            {success ? (
              <p className="text-center">
                Thank you for subscribing our newsletter.
              </p>
            ) : (
              <form onSubmit={formSubmit}>
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="row">
                  <div className="col-md-7">
                    <label className="checkbox-wrapper checkbox-wrapper--small">
                      <input
                        name="legal"
                        id="legal"
                        type="checkbox"
                        required
                        onChange={() => setLegal(!legal)}
                        value={legal}
                      />
                      <span className="checkmark checkmark--small"></span>
                      <span>
                        I agree to the processing of my personal data by
                        Prodinity, 134 Buckingham Palace Road, London SW1W 9SA,
                        United Kingdom, for the purpose of marketing services,
                        involving the use of products and services. Information
                        about the data processing, as well as your rights
                        regarding the submitted data is available in our Privacy
                        Policy
                      </span>
                    </label>
                  </div>
                  <div className="col-md-5 text-right">
                    <button
                      type="submit"
                      className="btn btn--outline btn--send btn--block"
                      disabled={!legal}
                    >
                      {send === true ? "Processing..." : "Subscribe"}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <video autoPlay={true} muted={true} loop={true} playsInline={true}>
        <source
          src={require("assets/videos/newsletter-background.mp4").default}
          type="video/mp4"
        />
      </video>
    </section>
  );
};

export default Newsletter;
